import React from 'react';

const JobDescription = ({ pageContext }) => (
  <section className="portfolio_details_area sec_pad">
    <div className="container p-5" >
      <h4>Responsibilities</h4>
      <ul>
        {pageContext.responsibilities.map((item, i) => (
          <li key={i} >
            {item}
          </li>
        ))}
      </ul>
      <h4>Required Skills</h4>
      <ul>
        {pageContext.requiredSkills.map((item, i) => (
          <li key={i} >
            {item}
          </li>
        ))}
      </ul>
      <h4>Bonus Skills</h4>
      <ul>
        {pageContext.bonusSkills.map((item, i) => (
          <li key={i} >
            {item}
          </li>
        ))}
      </ul>
    </div>
  </section>
);

export default JobDescription;
